import React, { Component } from "react";
import { Col } from "react-bootstrap";
import { Link } from "gatsby";
import Img from "gatsby-image";

const titleStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "white",
  fontWeight: "bold",
  textTransform: "uppercase",
  textShadow: "-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000"
};

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = { hovered: false };
  }

  render() {
    return (
      <Col
        className="p-0"
        onMouseOver={() => this.setState({ hovered: true })}
        onMouseOut={() => this.setState({ hovered: false })}
        style={{
          position: "relative",
          textAlign: "center",
          opacity: `${this.state.hovered ? 0.8 : 1}`,
          transition: "0.4s",
        }}
      >
        <Link to={this.props.route}>
          <Img
            fluid={this.props.image.fluid}
            key={this.props.image.id}
            alt={this.props.image.title}
            onMouseOver={() => this.setState({ hovered: true })}
            onMouseOut={() => this.setState({ hovered: false })}
            style={{ /*filter: "blur(1px)",  /*boxShadow: "3px -3px 24px 12px #000"*/ }}
          />
          <h4 style={titleStyle}>{this.props.title}</h4>
        </Link>
      </Col>
    );
  }
}

export default Section;
