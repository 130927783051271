import React, { useState } from "react";
import { Alert, Container, Row } from "react-bootstrap";
import { CookieBanner } from '@palmabit/react-cookie-law';

import { Link } from "gatsby";
import { graphql } from "gatsby";

import Header from "../components/header";
import Footer from "../components/footer";
import Section from "../components/section";
import Seo from "../components/seo";

const IndexPage = ({ data }) => {
  const [show, setShow] = useState(true);
  const sections = data.allContentfulSection.edges;
  const person = data.contentfulPerson;
  return (
    <React.Fragment>
      <Seo title="mg98photographer - Fotografo" description="Marco Garbi (mg98photographer), fotografo a Treviso disponibile per shooting e progetti innovativi.">
        {/*FontAwesome  icons*/}
        <link
          href="https://use.fontawesome.com/releases/v5.15.1/css/all.css"
          rel="stylesheet"
        />
      </Seo>
      <Header />
      <main>
        <Container fluid>
          <Row xs={2}>
            {sections.map((item, index) => {
                return (
                  <Section
                    key={item.node.id}
                    route={item.node.route}
                    title={item.node.name}
                    image={item.node.image}
                  />
                );
            })}
          </Row>
        </Container>
      </main>
      {show ? (
        <Alert
          variant="secondary"
          show={show}
          onClose={() => setShow(false)}
          dismissible
          style={{
            position: "fixed",
            bottom: "5%",
            left: "5%",
            right: "5%",
            transition: "0.4s",
            textAlign: "center",
            color: "black"
          }}
        >
          {person.banner} <Link to="/contacts">Contattami</Link>
        </Alert>
      ) : null}
      <CookieBanner
        message="Questo sito utilizza cookie tecnici e analytics anonimi di terze parti per offrirti la migliore qualità d'utilizzo. Continuando la navigazione si consente l'utilizzo dei cookie su questo sito."
        wholeDomain={true}
        policyLink="https://www.cookiesandyou.com/"
        privacyPolicyLinkText="Per saperne di più"
        showPreferencesOption={false}
        showStatisticsOption={false}
        showMarketingOption={false}
        managePreferencesButtonText="Preferenze"
        savePreferencesButtonText="Salva e chiudi"
        acceptButtonText="OK"
        onAccept={() => { }}
        onAcceptPreferences={() => { }}
        onAcceptStatistics={() => { }}
        onAcceptMarketing={() => { }}
        styles={{
          dialog: {
            position: "fixed",
            width: "100%",
            bottom: "0%",
            left: "0%",
            right: "0%",
            zIndex: "100000",
            backgroundColor: "rgb(248, 247, 247)",
            padding: "10px"
          },
          button: {
            color: "#FFFFFF",
            fontWeight: "bold",
            backgroundColor: "#000000",
            paddingLeft: "15px",
            paddingRight: "15px",
          }
        }}
      />
      <Footer />
    </React.Fragment>
  );
};

export default IndexPage;

export const indexQuery = graphql`
  {
    allContentfulSection(sort: { order: ASC, fields: position }) {
      edges {
        node {
          route
          name
          image {
            title
            id
            fluid(sizes: "100", quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          id
        }
      }
    }
    contentfulAsset(title: { eq: "index-cover" }) {
      fluid(sizes: "100", quality: 100) {
        ...GatsbyContentfulFluid
      }
      id
      title
      description
    }
    contentfulPerson {
      id
      banner
    }
  }
`;
